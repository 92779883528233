
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiStore, ApiMerchant, ApiBase } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { MBTaggings } from "magicbean-saas-common";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";
import _ from "lodash";

interface TaggingValue {
  short_key: string;
  items: Array<TaggingValueItem>;
}

interface TaggingValueItem {
  short_key: string;
  value: string | Array<string>;
}
export default defineComponent({
  name: "stores-information",
  components: { MBTaggings },
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const pimTaggingsRef = ref<null | HTMLFormElement>(null);
    const route = useRoute();
    const router = useRouter();
    const short_key = "merchant_channel_online_store_description";
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const { t } = useI18n();
    const taggingSource = ref([]);
    const taggingValues = ref([]);

    const getTaggingDataSource = () => {
      return ApiBase.getTaggingGroupData({ short_key: short_key })
        .then(({ data }) => {
          if (data.code == 0) {
            taggingSource.value =
              data.data.merchant_channel_online_store_description.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const details = ref({
      name: "",
      email: "",
      address: "",
      website: "",
    });

    const formData = ref({
      id: route.params.id,
      status: 10,
      name: "",
      short_key: "",
      website: "",
      email: "",
      telephone: "",
      telephone2: "",
      fax: "",
      merchant_id: "",
      overall_notes: "",
      tagging_data: [] as any[],
      __show: {
        created_uid: "",
        updated_uid: "",
      },
    });

    /******************************** 
      获取下拉框数据 
      merchant_id 商户
      country_iso_2国家
    *********************************/
    const options = ref({
      merchant_id: [],
      country_iso_2: [],
    });
    const getMerchantSourceData = async (value, id?) => {
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData(params);
      if (data.code == 0) {
        options.value.merchant_id = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };
    /******************************** 
      获取下拉框数据 end
    *********************************/

    const rules = ref({
      name: [
        {
          required: true,
          message: "Company Name is required",
          trigger: "change",
        },
      ],
      short_key: [
        {
          required: true,
          message: "Company Short-Key is required",
          trigger: "change",
        },
      ],
      telephone: [
        {
          required: true,
          message: "Telephone is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },
      ],
      merchant_id: [
        {
          required: true,
          message: "Parent Merchant is required",
          trigger: "change",
        },
      ],
    });

    const getPosyData = () => {
      loading.value = true;
      ApiStore.getStoreInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
            let { name, email, address, website } = JSON.parse(
              JSON.stringify(data.data)
            );
            details.value = {
              name,
              email,
              address,
              website,
            };
            getMerchantSourceData("", formData.value.merchant_id);
            taggingValues.value =
              data.data.tagging_data.merchant_channel_online_store_description.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const deleteStores = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Stores?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiStore.deleteStore({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Stores has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "online-store" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const submit = () => {
      let taggingData = pimTaggingsRef.value?.formData;
      let taggingArr: Array<TaggingValue> = [];
      let items: Array<TaggingValueItem> = [];
      for (let item in taggingData) {
        items.push({
          short_key: item,
          value: taggingData[item],
        });
      }
      let taggingObj: TaggingValue = {
        short_key: "merchant_channel_online_store_description",
        items: items,
      };
      taggingArr.push(taggingObj);
      console.log(taggingArr);
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          formData.value.tagging_data = taggingArr;
          ApiStore.updateStore(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
                let { name, email, address, website } = JSON.parse(
                  JSON.stringify(formData.value)
                );
                details.value = {
                  name,
                  email,
                  address,
                  website,
                };
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
      loading.value = true;
      Promise.all([getTaggingDataSource(), getPosyData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    });

    return {
      loading,
      details,
      formData,
      rules,
      options,
      formRef,
      submitButton,
      deleteButton,
      t,
      submit,
      getPosyData,
      deleteStores,
      taggingSource,
      taggingValues,
      pimTaggingsRef,
      searchMerchantSourceItems,
    };
  },
});
